<template>
    <InventoryMetersImportComp/>
</template>

<script>
export default {
  name: "InventoryMetersImport",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Inventory Import Meters", path:"", isActive: true}]
    }
  },
  components: {
    InventoryMetersImportComp: () => import("../../src/components/inventory/ImportMeters.vue"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>
